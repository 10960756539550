@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  font-family: 'Public Sans', sans-serif;
  background-color: #F2F4F6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  background-color: #F2F4F6;
}

header {
  background-color: white;
}

.avatar {
  width: 54px;
  height: 54px;
}

.login-form.card {
  height: fit-content;
  border-color: #eee;
}

.navbar {
  background-color: #fff; 
  /* height: calc(100vh - 93px); */
  min-height: calc(100vh - 93px);
}

.navbar nav {
  gap: 0.5rem;
}

.navbar a {
  font-size: 1.2em;
  text-decoration: none;
  line-height: 1;
  color: #3f3f3f;
  opacity: 0.9;
}

.navbar li {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #212121;
}

.navbar li.current {
  font-weight: 500;
}

.navbar li.current a {
  opacity: 1;
}

.custom-modal {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  z-index: 100;
}

/* .custom-modal .close-btn {
  --bs-btn-close-color: #FFF !important;
  fill: #FFF;
  stroke: #FFF;
  color: white;
} */

.close-btn {
  top: 32px;
  right: 32px;
  cursor: pointer;
}

.drop-container {
  cursor: pointer;
  min-height: 250px;
}

.drop-container-custom {
  min-height: 125px !important;
}

.drop-container img {
  max-width: 54px;
}

li.has-children {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.menu-icon {
  top: 15px;
  right: -30px;
}

.menu-item {
  list-style: none;
}

.menu-header {
  cursor: pointer;
}

.sub-menu {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.sub-menu.active {
  max-height: 500px; /* Ajusta este valor según sea necesario */
}

ul.sub-menu {
  display: block;
  transition: height 100ms ease-in-out;
}

ul.sub-menu .menu-icon {
  transition: transform 100ms ease-in-out;
}

ul.sub-menu.active .menu-icon {
  display: block;
  transform: rotate(180deg);
}

.current.has-children ul {
  display: flex;
  flex-direction: column;
}

.email-list {
  height: calc(100vh - 21rem);
  max-width: 100%;
  overflow-y: scroll;
  border-radius: 8px;
  border-top-left-radius: 0;
}

.email-item {
  cursor: pointer;
  transition: box-shadow 100ms ease-in-out;
  width: 95%;
}

.email-item + button {
  width: 5%;
}

.email-item-container:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.email-item:last-child {
  border-bottom: 0 !important;
}

.email-item:hover {
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.email-subject {
  overflow: hidden;
  position: relative;
}

.email-subject p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.email-details {
  z-index: 9000;
}

#from.non-validate,
.non-validate::placeholder {
  color: rgb(209, 116, 116) !important;
}

/* .text-area {
  margin-bottom: 4rem;
} */

.dropdown-item {
  cursor: pointer; 
}
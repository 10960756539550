@import url(https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*

    Adaptación responsive

*/

@media only screen and ( max-width: 1024px ) {

    header {
        max-height: 102px;
    }

    header img { 
        max-width: 200px !important;
    }
    
    .hamburguer-menu::after{
        display: none !important;
    }

    main {
        flex-direction: column;
        height: 100vh;
    }

    main aside.navbar {
        display: none !important;
    }

    main .custom-container {
        width: 100% !important;
        height: 100%;
    }

    .email-list .email-item-container .email-item {
        padding: 1rem 2rem !important;
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        grid-gap: 0 !important;
        gap: 0 !important;
        overflow-x: hidden;
    }

    .email-list .email-item-container .email-item .email-subject {
        overflow: hidden;
        flex-wrap: wrap;
        width: 100%;
    }

    .email-list .email-item-container .email-item div:last-child {
        display: none !important;
    }

    .email-list .email-item-container .email-item + button {
        width: 12%;
    }

    #write-email i { 
        margin-right: 0 !important;
    }

    #write-email span {
        display: none;
    }

    .custom-modal .mail-header {
        flex-direction: column;
        grid-gap: 0 !important;
        gap: 0 !important;
    }

    .custom-modal .mail-header > div:first-child,
    .custom-modal .mail-header.details > div > div:first-child  {
        border-bottom: 1px solid #dee2e6;
    }

    .custom-modal .mail-header.details > div:first-child {
        flex-direction: column;
        grid-gap: 0 !important;
        gap: 0 !important;
    }

    .app .google-login {
        margin-top: 15rem;
    }

}

@media only screen and (max-width: 425px) {

    .app .document {
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .app .document a {
        width: 100%;
    }

}
html {
  font-family: 'Public Sans', sans-serif;
  background-color: #F2F4F6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  background-color: #F2F4F6;
}

header {
  background-color: white;
}

.avatar {
  width: 54px;
  height: 54px;
}

.login-form.card {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-color: #eee;
}

.navbar {
  background-color: #fff; 
  /* height: calc(100vh - 93px); */
  min-height: calc(100vh - 93px);
}

.navbar nav {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.navbar a {
  font-size: 1.2em;
  text-decoration: none;
  line-height: 1;
  color: #3f3f3f;
  opacity: 0.9;
}

.navbar li {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  color: #212121;
}

.navbar li.current {
  font-weight: 500;
}

.navbar li.current a {
  opacity: 1;
}

.custom-modal {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 100;
}

/* .custom-modal .close-btn {
  --bs-btn-close-color: #FFF !important;
  fill: #FFF;
  stroke: #FFF;
  color: white;
} */

.close-btn {
  top: 32px;
  right: 32px;
  cursor: pointer;
}

.drop-container {
  cursor: pointer;
  min-height: 250px;
}

.drop-container-custom {
  min-height: 125px !important;
}

.drop-container img {
  max-width: 54px;
}

li.has-children {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.menu-icon {
  top: 15px;
  right: -30px;
}

.menu-item {
  list-style: none;
}

.menu-header {
  cursor: pointer;
}

.sub-menu {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.sub-menu.active {
  max-height: 500px; /* Ajusta este valor según sea necesario */
}

ul.sub-menu {
  display: block;
  transition: height 100ms ease-in-out;
}

ul.sub-menu .menu-icon {
  transition: transform 100ms ease-in-out;
}

ul.sub-menu.active .menu-icon {
  display: block;
  transform: rotate(180deg);
}

.current.has-children ul {
  display: flex;
  flex-direction: column;
}

.email-list {
  height: calc(100vh - 21rem);
  max-width: 100%;
  overflow-y: scroll;
  border-radius: 8px;
  border-top-left-radius: 0;
}

.email-item {
  cursor: pointer;
  transition: box-shadow 100ms ease-in-out;
  width: 95%;
}

.email-item + button {
  width: 5%;
}

.email-item-container:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.email-item:last-child {
  border-bottom: 0 !important;
}

.email-item:hover {
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.email-subject {
  overflow: hidden;
  position: relative;
}

.email-subject p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.email-details {
  z-index: 9000;
}

#from.non-validate,
.non-validate::placeholder {
  color: rgb(209, 116, 116) !important;
}

/* .text-area {
  margin-bottom: 4rem;
} */

.dropdown-item {
  cursor: pointer; 
}

/*

    Adaptación responsive

*/

@media only screen and ( max-width: 1024px ) {

    header {
        max-height: 102px;
    }

    header img { 
        max-width: 200px !important;
    }
    
    .hamburguer-menu::after{
        display: none !important;
    }

    main {
        flex-direction: column;
        height: 100vh;
    }

    main aside.navbar {
        display: none !important;
    }

    main .custom-container {
        width: 100% !important;
        height: 100%;
    }

    .email-list .email-item-container .email-item {
        padding: 1rem 2rem !important;
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        gap: 0 !important;
        overflow-x: hidden;
    }

    .email-list .email-item-container .email-item .email-subject {
        overflow: hidden;
        flex-wrap: wrap;
        width: 100%;
    }

    .email-list .email-item-container .email-item div:last-child {
        display: none !important;
    }

    .email-list .email-item-container .email-item + button {
        width: 12%;
    }

    #write-email i { 
        margin-right: 0 !important;
    }

    #write-email span {
        display: none;
    }

    .custom-modal .mail-header {
        flex-direction: column;
        gap: 0 !important;
    }

    .custom-modal .mail-header > div:first-child,
    .custom-modal .mail-header.details > div > div:first-child  {
        border-bottom: 1px solid #dee2e6;
    }

    .custom-modal .mail-header.details > div:first-child {
        flex-direction: column;
        gap: 0 !important;
    }

    .app .google-login {
        margin-top: 15rem;
    }

}

@media only screen and (max-width: 425px) {

    .app .document {
        flex-direction: column !important;
        justify-content: flex-start !important;
        align-items: flex-start !important;
    }

    .app .document a {
        width: 100%;
    }

}